<template>
    <div class="card" style="min-height: 200px">
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th></th>
                    <th>Customer Name</th>
                    <th>Invoice Date</th>
                    <th>Invoice NO</th>
                    <th class="text-right">Invoice Amount</th>
                    <th class="text-right">Already Received</th>
                    <th class="text-right">Due</th>
                    <th class="text-right">Received Now</th>
                </tr>
                </thead>
                <tbody>

                <template v-for="(item, i) in payBills" :key="i">
                    <BillPayTableRow :item="item" />
                </template>

                <tr>
                    <td colspan="7" class="text-right"><h4>Total Receive</h4></td>
                    <td class="text-right">
                        {{total}}
                    </td>
                </tr>

                </tbody>
            </table>
            <!-- <p v-if="!purchase.length" class="text-center mt-5">No data</p> -->
        </div>
    </div>
</template>

<script setup>
import BillPayTableRow from '@/components/molecule/company/inventory/sales/BillPayTableRow.vue'
    import { inject, computed } from 'vue'
    // const payBills = inject('payBills')
    const props = defineProps({
        payBills: Array,
    })

    const total = computed(() => {
      return props.payBills.reduce((sum, {payment}) => sum + Number(payment), 0)
    })
</script>
